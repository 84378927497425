import { Box, Container, Heading, Stack, StackDivider } from "@chakra-ui/react";
import React from "react";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import MaskSlidInAnimation from "../DefinitionComponents/Animation-Components/MaskSlideInAnimation";
import InViewTrigger from "../DefinitionComponents/Utility/UX/InViewTrigger";

function IntroComponent2colWhiteData({ headline, text1, text2, variant }) {
  return (
    <Box w="100%">
      <Container variant="layoutContainer" px={{ base: "0", lg: "0" }} py={20}>
        <Box w={{ lg: "100%", base: "100%" }}>
          <InViewTrigger threshold={0.4} triggerOnce={false}>
            {(inView) => (
              <Stack
                direction={{ base: "column", md: "row" }}
                divider={<StackDivider borderColor="gray.200" />}
                spacing={4}
                align="stretch"
              >
                <Box minW={variant == "thirds" ? "25%" : "50%"}>
                  <MaskSlidInAnimation threshold={1} duration={0.6}>
                    <Heading
                      fontSize="5xl"
                      fontWeight="normal"
                      mb="4"
                      w={"100%"}
                      letterSpacing="tighter"
                      color="brand.green"
                    >
                      {headline}
                    </Heading>
                  </MaskSlidInAnimation>
                </Box>
                <Box>
                  <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
                    <Heading
                      fontSize="2xl"
                      fontWeight="normal"
                      mb="4"
                      color="white"
                    >
                      {text1}
                    </Heading>
                  </FadeInAnimation>
                  <FadeInAnimation threshold={0.4} duration={1} delay={0.8}>
                    <Heading
                      dangerouslySetInnerHTML={{
                        __html: text2,
                      }}
                      fontSize="xl"
                      fontWeight="normal"
                      color="white"
                    ></Heading>
                  </FadeInAnimation>
                </Box>
              </Stack>
            )}
          </InViewTrigger>
        </Box>
      </Container>
    </Box>
  );
}

export default IntroComponent2colWhiteData;
